import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GlobalContext } from "../context/GlobalContext";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { SERVER } from "../utils/constants";
import BackComponent from "../components/common/BackComponent";

const DetailsScreen = () => {
  const { courses } = useContext(GlobalContext);
  const [course, setCourse] = useState({});
  const location = useLocation();
  const code = location.state.code;
  const navigate = useNavigate();

  useEffect(() => {
    let data = courses.filter((item) => {
      return item.code === code;
    });
    let classesData = [...data[0].classes];
    let classList = classesData.map((item) => {
      return {
        ...item,
        course_date_end: moment(item.course_date_end).format("LL"),
        course_date_start: moment(item.course_date_start).format("LL"),
      };
    });
    console.log("course: ", { ...data[0], classes: classList });
    setCourse({ ...data[0], classes: classList });
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Classes",
      width: 400,
      renderCell: (params) => {
        const { course_code, course_date_start, course_date_end } = params.row;
        return (
          <Box>
            <Typography variant="body2" color="initial">
              {course_code} {course_date_start} {" to "} {course_date_end}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "rendered",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              navigate(`/signmeup`, {
                state: {
                  start: params.row.course_date_start,
                  end: params.row.course_date_end,
                  title: params.row.course_title,
                },
              });
            }}
          >
            Sign me up!
          </Button>
        );
      },
    },
    {
      field: "id",
      headerName: "id",
      hide: true,
    },
  ];

  const handleDownloadPdf = async (url) => {
    window.open(`${SERVER}/api/download-pdf?pdfID=${url}`);
  };

  return (
    <>
      {course ? (
        <>
          <Box
            sx={{
              bgcolor: "background.paper",
              pt: 4,
              pb: 4,
            }}
          >
            <Container maxWidth="lg">
              <BackComponent />
              <Typography
                component="h4"
                variant="h4"
                align="center"
                color="text.primary"
                gutterBottom
                sx={{ mb: 6 }}
              >
                {course.title}
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <img
                      src={
                        course.url_image
                          ? `${SERVER}${course.url_image}`
                          : `${SERVER}/placeholder.png`
                      }
                      alt={course.name}
                      style={{
                        width: "100%",
                        height: 400,
                        objectFit: "contain",
                        borderWidth: 1,
                        borderColor: "black",
                        borderStyle: "solid",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="initial"
                        sx={{ fontWeight: "bold", mt: 5 }}
                      >
                        Description:
                      </Typography>
                      <Typography variant="body1" color="initial">
                        {course.description}
                      </Typography>
                      <Typography
                        variant="h6"
                        color="initial"
                        sx={{ fontWeight: "bold" }}
                      >
                        Code: {course.code}
                      </Typography>
                      <Typography
                        variant="h6"
                        color="initial"
                        sx={{ fontWeight: "bold" }}
                      >
                        Duration in hours: {course.duration}
                      </Typography>
                      <Typography
                        variant="h6"
                        color="initial"
                        sx={{ fontWeight: "bold" }}
                      >
                        Pre-Requirements:
                      </Typography>
                      <Typography variant="body1" color="initial">
                        {course.pre_requirement}
                      </Typography>
                      <Typography
                        variant="h6"
                        color="initial"
                        sx={{ fontWeight: "bold" }}
                      >
                        Required Materials:
                      </Typography>
                      <Typography variant="body1" color="initial">
                        {course.material_requirement}
                      </Typography>
                      <Typography
                        variant="h6"
                        color="initial"
                        sx={{ fontWeight: "bold" }}
                      >
                        Rules
                      </Typography>
                      <Typography variant="body1" color="initial">
                        {course.rules}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5" color="initial">
                    Classes:
                  </Typography>
                  {Object.keys(course).length > 0 && (
                    <Box sx={{ height: 375 }}>
                      <DataGrid
                        rows={course.classes}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        experimentalFeatures={{ newEditingApi: true }}
                      />
                    </Box>
                  )}
                  <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    disabled={!course.url_pdf}
                    onClick={() => handleDownloadPdf(course.url_pdf)}
                  >
                    Print this course
                  </Button>
                  {/* <EmailForm /> */}
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      ) : (
        ""
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default DetailsScreen;
